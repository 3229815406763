import isPropValid from '@emotion/is-prop-valid';
import {
  AppService,
  Challenge,
  CommutingTrip,
  Employee,
  Event,
  EventService,
  FMDConfig,
  Geogroup,
  GeogroupService,
  Partner,
  PartnerService,
  Period,
  TEmployeeTransportHabits,
  TMobilitySurvey,
  TPartnerContract,
  TStripeProduct,
  TSubGroupType,
  User,
  UserGeogroup,
  UserPlace,
  useCancellablePromise,
} from '@geovelo-frontends/commons';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'moment/locale/fr';
import { Font } from '@react-pdf/renderer';
import { SnackbarProvider } from 'notistack';
import { useEffect, useRef, useState } from 'react';
import { HelmetProvider, HelmetServerState } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { StyleSheetManager } from 'styled-components';

import NunitoBold from '../assets/fonts/NunitoSans_10pt_SemiCondensed-Black.ttf';
import NunitoItalic from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Italic.ttf';
import NunitoRegular from '../assets/fonts/NunitoSans_7pt_SemiCondensed-Medium.ttf';
import { environment } from '../environment';

import {
  AppContext,
  TGetStartedAdminProgression,
  TGetStartedAdminStepProgression,
  TGetStartedAdminSteps,
  TGetStartedAdminSubsteps,
  TGetStartedEmployeeSteps,
  TPermissionKey,
  getStartedAdminSteps,
  getStartedEmployeeSteps,
  offersMap,
} from './context';
import i18n from './i18n';
import Router, { TRouterRef } from './router';
import theme from './theme';
import './tracking';

Font.register({
  family: 'Nunito',
  fonts: [
    { src: NunitoRegular, fontWeight: 400 },
    { src: NunitoItalic, fontWeight: 400, fontStyle: 'italic' },
    { src: NunitoBold, fontWeight: 700 },
  ],
});

const helmetContext = {} as {
  helmet: HelmetServerState;
};

function App(): JSX.Element {
  const history = useRef([]);
  const [initialized, setInitialized] = useState(false);
  const [highlightedEvent, setHighlightedEvent] = useState<Event>();
  const [highlightedEventChallenge, setHighlightedEventChallenge] = useState<Challenge | null>();

  // user context
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [userIsRegister, setUserIsRegister] = useState<boolean>();
  const [userGetStartedProgression, setUserGetStartedProgression] = useState<{
    allDone: boolean;
    doneCount: number;
    ignoredSteps: { [key in TGetStartedEmployeeSteps]?: boolean };
    stepsDone: { [key in TGetStartedEmployeeSteps]: boolean };
  }>();
  const [userEmployee, setUserEmployee] = useState<Employee | null>();
  const [userEmployees, setUserEmployees] =
    useState<Array<{ employee: Employee; partner: Partner }>>();
  const [userTeam, setUserTeam] = useState<UserGeogroup | null>();
  const [userSite, setUserSite] = useState<UserGeogroup | null>();
  const [userHome, setUserHome] = useState<UserPlace | null>();
  const [userWorks, setUserWorks] = useState<UserPlace[]>();
  const [userCommutingTrips, setUserCommutingTrips] = useState<CommutingTrip[]>();
  const [userTransportHabits, setUserTransportHabits] = useState<TEmployeeTransportHabits | null>();
  const [userCreationReasonDialogSeen, setUserCreationReasonDialogSeen] = useState<boolean>();

  // partner context
  const [currentPartner, setCurrentPartner] = useState<Partner | null>();
  const [currentPartnerGetStartedProgression, setCurrentPartnerGetStartedProgression] =
    useState<TGetStartedAdminProgression>();
  const [currentPartnerGetStartedSkippedSteps, setCurrentPartnerGetStartedSkippedSteps] = useState<{
    [key in TGetStartedAdminSteps]?: { [subkey in TGetStartedAdminSubsteps[key]]?: boolean };
  }>({});
  const [currentPartnerContract, setCurrentPartnerContract] = useState<TPartnerContract | null>();
  const [currentPartnerInvitationCode, setCurrentPartnerInvitationCode] = useState<string | null>();
  const [currentPartnerGeogroup, setCurrentPartnerGeogroup] = useState<Geogroup | null>();
  const [currentPartnerSubgroupTypes, setCurrentPartnerSubgroupTypes] = useState<TSubGroupType[]>();
  const [currentPartnerTeams, setCurrentPartnerTeams] = useState<Geogroup[] | null>();
  const [currentPartnerSubsidiaries, setCurrentPartnerSubsidiaries] = useState<Geogroup[] | null>();
  const [currentPartnerRegions, setCurrentPartnerRegions] = useState<Geogroup[] | null>();
  const [currentPartnerSites, setCurrentPartnerSites] = useState<Geogroup[] | null>();
  const [currentPartnerEmployeesCount, setCurrentPartnerEmployeesCount] = useState<number>();
  const [currentPartnerInactiveEmployees, setCurrentPartnerInactiveEmployees] = useState<
    Employee[] | null
  >();
  const [currentPartnerFMDConfig, setCurrentPartnerFMDConfig] = useState<FMDConfig | null>();
  const [currentPartnerMobilitySurveys, setCurrentPartnerMobilitySurveys] =
    useState<TMobilitySurvey[]>();
  const [currentPartnerPermissions, setCurrentPartnerPermissions] = useState<{
    [key in TPermissionKey]: boolean;
  }>(offersMap['geovelo-entreprise-free'].permissions);
  const [currentPartnerHasChallenges, setCurrentPartnerHasChallenges] = useState<boolean>();
  const [currentPartnerAvailableEvents, setCurrentPartnerAvailableEvents] = useState<{
    current: Event[];
    future: Event[];
  } | null>();
  const firstMembersAddedDialogOpenRef = useRef<boolean>(false);

  // stripe context
  const [stripeProducts, setStripeProducts] = useState<TStripeProduct[]>();

  const routerRef = useRef<TRouterRef>(null);
  const { cancellablePromise, cancelPromises } = useCancellablePromise();
  const {
    cancellablePromise: cancellableHighlightedEventChallengePromise,
    cancelPromises: cancelHighlightedEventChallengePromises,
  } = useCancellablePromise();

  useEffect(() => {
    AppService.environment = environment;
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized && environment.highlightedEventId)
      getHighlightedEvent(environment.highlightedEventId);
  }, [initialized]);

  useEffect(() => {
    if (
      userEmployee?.isAdminOrAnimator &&
      currentPartner &&
      currentPartnerEmployeesCount !== undefined &&
      currentPartnerHasChallenges !== undefined
    ) {
      const steps: {
        [key in TGetStartedAdminSteps]: TGetStartedAdminStepProgression<
          TGetStartedAdminSubsteps[key]
        >;
      } = {
        accountConfig: { doneCount: 0, allDone: false, substeps: [] },
        firstChallenge: { doneCount: 0, allDone: false, substeps: [] },
        discoverOffers: { doneCount: 0, allDone: false, substeps: [] },
      };

      getStartedAdminSteps.forEach((key) => {
        switch (key) {
          case 'accountConfig': {
            const hasLogo = Boolean(currentPartner.icon);
            const hasEmployees = currentPartnerEmployeesCount > 1;

            steps.accountConfig = {
              doneCount: (hasLogo ? 1 : 0) + (hasEmployees ? 1 : 0),
              allDone: hasLogo && hasEmployees,
              substeps: [
                { key: 'logo', done: hasLogo },
                { key: 'invitations', done: hasEmployees },
              ],
            };

            break;
          }
          case 'firstChallenge': {
            const hasFirstChallenge = currentPartnerHasChallenges;
            const done =
              hasFirstChallenge ||
              Boolean(userEmployee.surveyAnswers.getStartedAdminDoneSteps_firstChallenge);

            steps.firstChallenge = {
              doneCount: done ? 1 : 0,
              allDone: done,
              substeps: [{ key: 'firstChallenge', done }],
            };

            break;
          }
          case 'discoverOffers': {
            const isFree =
              currentPartnerContract &&
              currentPartnerContract.contractTemplate.code === 'geovelo-entreprise-free';
            const done =
              !isFree ||
              Boolean(userEmployee.surveyAnswers.getStartedAdminDoneSteps_discoverOffers);
            steps.discoverOffers = {
              doneCount: done ? 1 : 0,
              allDone: done,
              substeps: [{ key: 'discoverOffers', done }],
            };

            break;
          }
          default:
            break;
        }
      });

      const doneCount = getStartedAdminSteps.filter((key) => steps[key].allDone).length;
      const allDone = getStartedAdminSteps.every((key) => steps[key].allDone);

      setCurrentPartnerGetStartedProgression({
        doneCount,
        allDone,
        steps,
      });
    }

    return () => {
      setCurrentPartnerGetStartedProgression(undefined);
    };
  }, [userEmployee, currentPartner, currentPartnerEmployeesCount, currentPartnerHasChallenges]);

  useEffect(() => {
    if (
      userEmployee &&
      currentPartnerTeams !== undefined &&
      currentPartnerSites !== undefined &&
      currentUser &&
      userEmployee &&
      userWorks &&
      userTeam !== undefined &&
      userSite !== undefined
    ) {
      const ignoredSteps = getStartedEmployeeSteps.reduce<{
        [key in TGetStartedEmployeeSteps]?: boolean;
      }>((res, key) => {
        res[key] =
          key === 'team' && (!currentPartnerTeams || currentPartnerTeams.length === 0)
            ? true
            : key === 'site' && (!currentPartnerSites || currentPartnerSites.length < 2)
              ? true
              : key === 'site' &&
                  userEmployee.surveyAnswers.getStartedEmployeeIgnoredSteps_site &&
                  userWorks.length > 0
                ? false
                : key === 'work' && currentPartnerSites && currentPartnerSites.length > 0
                  ? true
                  : Boolean(userEmployee.surveyAnswers[`getStartedEmployeeIgnoredSteps_${key}`]);

        return res;
      }, {});

      const stepsDone: { [key in TGetStartedEmployeeSteps]: boolean } = {
        work: false,
        site: false,
        profilePicture: false,
        team: false,
      };

      getStartedEmployeeSteps.forEach((key) => {
        switch (key) {
          case 'work':
            if (userWorks.length > 0) {
              stepsDone.work = true;
            }
            break;
          case 'site':
            if (
              userSite ||
              (userEmployee.surveyAnswers.getStartedEmployeeIgnoredSteps_site &&
                userWorks.length > 0)
            ) {
              stepsDone.site = true;
            }
            break;
          case 'profilePicture':
            if (currentUser.profilePicture) {
              stepsDone.profilePicture = true;
            }
            break;
          case 'team':
            if (userTeam) {
              stepsDone.team = true;
            }
            break;
          default:
            break;
        }
      });

      const doneCount = getStartedEmployeeSteps.filter(
        (key) => stepsDone[key] && !ignoredSteps[key],
      ).length;
      const allDone = getStartedEmployeeSteps.every((key) => stepsDone[key] || ignoredSteps[key]);

      setUserGetStartedProgression({ stepsDone, ignoredSteps, doneCount, allDone });
    }

    return () => {
      setUserGetStartedProgression(undefined);
    };
  }, [
    currentPartnerTeams,
    currentPartnerSites,
    currentUser,
    userEmployee,
    userWorks,
    userTeam,
    userSite,
  ]);

  useEffect(() => {
    if (currentPartnerGeogroup && highlightedEvent)
      getHighlightedEventChallenge(currentPartnerGeogroup, highlightedEvent);

    return () => {
      cancelHighlightedEventChallengePromises();
      setHighlightedEventChallenge(undefined);
    };
  }, [currentPartnerGeogroup]);

  async function getHighlightedEvent(id: number) {
    try {
      const event = await EventService.getEvent(id);

      setHighlightedEvent(event);
      if (currentPartnerGeogroup) getHighlightedEventChallenge(currentPartnerGeogroup, event);
    } catch (err) {
      console.error(err);
    }
  }

  async function getHighlightedEventChallenge(geogroup: Geogroup, highlightedEvent: Event) {
    try {
      const { challenges } = await cancellableHighlightedEventChallengePromise(
        GeogroupService.getChallenges(geogroup.id, {
          page: 1,
          pageSize: 100,
          period: new Period('custom', highlightedEvent.startDate, highlightedEvent.endDate),
        }),
      );

      setHighlightedEventChallenge(
        challenges.find(({ eventId }) => eventId === highlightedEvent.id) || null,
      );
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  async function getPartnerEmployees(partner: Partner) {
    setCurrentPartnerEmployeesCount(undefined);
    setCurrentPartnerInactiveEmployees(undefined);

    try {
      const inactiveEmployees: Employee[] = [];
      const [{ count }, { count: inactiveEmployeesCount, employees }] = await cancellablePromise(
        Promise.all([
          PartnerService.getEmployees(partner, {
            statuses: ['joined', 'waitingForApproval'],
            page: 1,
            pageSize: 1,
            ordering: '-id',
            query: '{id}',
          }),
          PartnerService.getEmployees(partner, {
            statuses: ['waitingUserToJoin'],
            page: 1,
            pageSize: 100,
            ordering: '-id',
            query: '{id, user{username}, professional_email}',
          }),
        ]),
      );
      inactiveEmployees.push(...employees);

      if (inactiveEmployeesCount > 100) {
        const results = await Promise.all(
          new Array(Math.ceil(inactiveEmployeesCount / 100) - 1).fill(null).map((_, index) =>
            PartnerService.getEmployees(partner, {
              statuses: ['waitingUserToJoin'],
              page: index + 2,
              pageSize: 100,
              ordering: '-id',
              query: '{id, user{username}, professional_email}',
            }),
          ),
        );

        inactiveEmployees.push(...results.flatMap(({ employees }) => employees));
      }

      setCurrentPartnerEmployeesCount(count);
      setCurrentPartnerInactiveEmployees(inactiveEmployees);
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
        setCurrentPartnerEmployeesCount(0);
        setCurrentPartnerInactiveEmployees(null);
      }
    }
  }

  async function getAvailableEvents(partner: Partner, geogroup: Geogroup) {
    try {
      const [{ count }, runningEvents, futureEvents] = await cancellablePromise(
        Promise.all([
          GeogroupService.getChallenges(geogroup.id, {
            page: 1,
            pageSize: 1,
            state: ['running', 'future'],
            order: 'start_datetime',
          }),
          EventService.getAvailableEvents({
            partner,
            geogroupId: geogroup.id,
            page: 1,
            pageSize: 3,
            state: ['running'],
            automaticSubscription: 'CHALLENGE_EDITORIALIZED_SUBSCRIBER',
          }),
          EventService.getAvailableEvents({
            partner,
            geogroupId: geogroup.id,
            page: 1,
            pageSize: 3,
            state: ['future'],
            automaticSubscription: 'CHALLENGE_EDITORIALIZED_SUBSCRIBER',
          }),
        ]),
      );

      setCurrentPartnerHasChallenges(count > 0);
      setCurrentPartnerAvailableEvents({ current: runningEvents, future: futureEvents });
    } catch (err) {
      if (err instanceof Error && err?.name !== 'CancelledPromiseError') {
        console.error(err);
      }
    }
  }

  if (!initialized) return <></>;

  return (
    <AppContext.Provider
      value={{
        history,
        app: { highlightedEvent, highlightedEventChallenge },
        user: {
          current: currentUser,
          isRegister: userIsRegister,
          getStartedProgression: userGetStartedProgression,
          employee: userEmployee,
          employees: userEmployees,
          team: userTeam,
          site: userSite,
          home: userHome,
          works: userWorks,
          commutingTrips: userCommutingTrips,
          transportHabits: userTransportHabits,
          creationReasonDialogSeen: userCreationReasonDialogSeen,
        },
        partner: {
          current: currentPartner,
          getStartedProgression: currentPartnerGetStartedProgression,
          getStartedSkippedSteps: currentPartnerGetStartedSkippedSteps,
          contract: currentPartnerContract,
          invitationCode: currentPartnerInvitationCode,
          geogroup: currentPartnerGeogroup,
          subgroupTypes: currentPartnerSubgroupTypes,
          teams: currentPartnerTeams,
          subsidiaries: currentPartnerSubsidiaries,
          regions: currentPartnerRegions,
          sites: currentPartnerSites,
          employeesCount: currentPartnerEmployeesCount,
          inactiveEmployees: currentPartnerInactiveEmployees,
          fmdConfig: currentPartnerFMDConfig,
          mobilitySurveys: currentPartnerMobilitySurveys,
          permissions: currentPartnerPermissions,
          availableEvents: currentPartnerAvailableEvents,
          firstMembersAddedDialogOpenRef,
        },
        stripe: { products: stripeProducts },
        actions: {
          setStripeProducts,
          setHighlightedEventChallenge,
          setCurrentUser,
          selectCompany: (employee, partner) => routerRef.current?.selectCompany(employee, partner),
          setCurrentPartner,
          setPartnerContract: setCurrentPartnerContract,
          setPartnerInvitationCode: setCurrentPartnerInvitationCode,
          setPartnerGeogroup: setCurrentPartnerGeogroup,
          setPartnerSubgroupTypes: setCurrentPartnerSubgroupTypes,
          setPartnerTeams: setCurrentPartnerTeams,
          setPartnerSubsidiaries: setCurrentPartnerSubsidiaries,
          setPartnerRegions: setCurrentPartnerRegions,
          setPartnerSites: setCurrentPartnerSites,
          setPartnerEmployeesCount: setCurrentPartnerEmployeesCount,
          setPartnerInactiveEmployees: setCurrentPartnerInactiveEmployees,
          setPartnerFMDConfig: setCurrentPartnerFMDConfig,
          setPartnerMobilitySurveys: setCurrentPartnerMobilitySurveys,
          setPartnerPermissions: setCurrentPartnerPermissions,
          setPartnerHasChallenges: setCurrentPartnerHasChallenges,
          setPartnerAvailableEvents: setCurrentPartnerAvailableEvents,
          setPartnerGetStartedSkippedSteps: setCurrentPartnerGetStartedSkippedSteps,
          setUserIsRegister,
          setUserEmployee,
          setUserEmployees,
          setUserTeam,
          setUserSite,
          setUserHome,
          setUserWorks,
          setUserCommutingTrips,
          setUserTransportHabits,
          setUserCreationReasonDialogSeen,
          getPartnerEmployees,
          getAvailableEvents,
        },
      }}
    >
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <StyleSheetManager
            shouldForwardProp={(propName, elementToBeRendered) =>
              typeof elementToBeRendered === 'string' ? isPropValid(propName) : true
            }
          >
            <CssBaseline />
            <SnackbarProvider
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              maxSnack={3}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <HelmetProvider context={helmetContext}>
                  <BrowserRouter>
                    <Router
                      cancellablePromise={cancellablePromise}
                      cancelPromises={cancelPromises}
                      ref={routerRef}
                    />
                  </BrowserRouter>
                </HelmetProvider>
              </LocalizationProvider>
            </SnackbarProvider>
          </StyleSheetManager>
        </ThemeProvider>
      </I18nextProvider>
    </AppContext.Provider>
  );
}

export default App;
